html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  background: #f5f5f5;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.MuiTableCell-head {
  font-weight: 600 !important;
}

.progress-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.progress-root {
  display: inline-block;
  width: 40px;
  height: 40px;
  color: #1976d2;
  animation: progress-rotate 1.4s linear infinite;
}

.progress-root svg {
  display: block;
}

.progress-root circle {
  stroke: currentColor;
  animation: progress-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
}

@keyframes progress-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
} 
